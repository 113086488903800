export const COLOR_NAME_CLASSES = {
  "neon-pink": {
    borderColorClassName: "border-neon-pink",
    shadowCardClassName: "shadow-card-neon-pink",
    bgColorClassName: "bg-neon-pink",
    textColorClassName: "text-neon-pink",
  },
  gray: {
    shadowCardClassName: "shadow-card-gray",
    bgColorClassName: "bg-gray",
    textColorClassName: "text-gray",
  },
  "gray-500": {
    bgColorClassName: "bg-gray-500",
    textColorClassName: "text-gray-500",
    borderColorClassName: "border-gray-500",
  },
  "coral-red": {
    glowClassName: "hover:shadow-glow-coral-red",
    groupTextColorClassName: "group:text-coral-red",
    hoverTextColorClassName: "hover:text-coral-red",
    hoverBgColorClassName: "hover:bg-coral-red",
    hoverRingColorClassName: "hover:ring-coral-red",
    bgColorClassName: "bg-coral-red",
    textColorClassName: "text-coral-red",
    ringColorClassName: "ring-coral-red",
    borderColorClassName: "border-coral-red",
  },
  "coral-red-lighter": {
    glowClassName: "hover:shadow-glow-coral-red-lighter",
    groupTextColorClassName: "group:text-coral-red-lighter",
    hoverTextColorClassName: "hover:text-coral-red-lighter",
    hoverBgColorClassName: "hover:bg-coral-red-lighter",
    hoverRingColorClassName: "hover:ring-coral-red-lighter",
    bgColorClassName: "bg-coral-red-lighter",
    textColorClassName: "text-coral-red-lighter",
    ringColorClassName: "ring-coral-red-lighter",
  },
  "coral-red-lightest": {
    bgColorClassName: "bg-coral-red-lightest",
  },
  "app-blue": {
    glowClassName: "hover:shadow-glow-app-blue",
    groupTextColorClassName: "group:text-app-blue",
    hoverTextColorClassName: "hover:text-app-blue",
    hoverBgColorClassName: "hover:bg-app-blue",
    hoverRingColorClassName: "hover:ring-app-blue",
    bgColorClassName: "bg-app-blue",
    textColorClassName: "text-app-blue",
    ringColorClassName: "ring-app-blue",
  },
  "rich-black": {
    groupTextColorClassName: "group:text-rich-black",
    hoverTextColorClassName: "hover:text-rich-black",
    hoverBgColorClassName: "hover:bg-rich-black",
    hoverRingColorClassName: "hover:ring-rich-black",
    bgColorClassName: "bg-rich-black",
    textColorClassName: "text-rich-black",
    ringColorClassName: "ring-rich-black",
  },
  "majorelle-blue": {
    glowClassName: "hover:shadow-glow-majorelle-blue",
    groupTextColorClassName: "group:text-majorelle-blue",
    hoverTextColorClassName: "hover:text-majorelle-blue",
    hoverBgColorClassName: "hover:bg-majorelle-blue",
    hoverRingColorClassName: "hover:ring-majorelle-blue",
    bgColorClassName: "bg-majorelle-blue",
    textColorClassName: "text-majorelle-blue",
    ringColorClassName: "ring-majorelle-blue",
  },
  "majorelle-blue-lighter": {
    glowClassName: "hover:shadow-glow-majorelle-blue-lighter",
    groupTextColorClassName: "group:text-majorelle-blue-lighter",
    hoverTextColorClassName: "hover:text-majorelle-blue-lighter",
    hoverBgColorClassName: "hover:bg-majorelle-blue-lighter",
    hoverRingColorClassName: "hover:ring-majorelle-blue-lighter",
    bgColorClassName: "bg-majorelle-blue-lighter",
    textColorClassName: "text-majorelle-blue-lighter",
    ringColorClassName: "ring-majorelle-blue-lighter",
  },
  black: {
    groupTextColorClassName: "group:text-black",
    hoverTextColorClassName: "hover:text-black",
    hoverBgColorClassName: "hover:bg-black",
    hoverRingColorClassName: "hover:ring-black",
    bgColorClassName: "bg-black",
    textColorClassName: "text-black",
    ringColorClassName: "ring-black",
  },
  white: {
    groupTextColorClassName: "group:text-white",
    hoverTextColorClassName: "hover:text-white",
    hoverBgColorClassName: "hover:bg-white",
    hoverRingColorClassName: "hover:ring-white",
    bgColorClassName: "bg-white",
    textColorClassName: "text-white",
    ringColorClassName: "ring-white",
  },
  transparent: {
    groupTextColorClassName: "group:text-transparent",
    hoverTextColorClassName: "hover:text-transparent",
    hoverBgColorClassName: "hover:bg-transparent",
    hoverRingColorClassName: "hover:ring-transparent",
    bgColorClassName: "bg-transparent",
    textColorClassName: "text-transparent",
    ringColorClassName: "ring-transparent",
  },
  "dark-green": {
    bgColorClassName: "bg-dark-green",
    textColorClassName: "text-dark-green",
    borderColorClassName: "border-dark-green",
  },
  "aquamarine-green": {
    bgColorClassName: "bg-aquamarine-green",
  },
};
