import React from "react";

export default function Twitter({ size = 24, className = "" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M23.5 2.9998C22.5424 3.67528 21.4821 4.19191 20.36 4.5298C19.7577 3.83731 18.9573 3.34649 18.067 3.12373C17.1767 2.90096 16.2395 2.957 15.3821 3.28426C14.5247 3.61151 13.7884 4.1942 13.273 4.95352C12.7575 5.71283 12.4877 6.61214 12.5 7.5298V8.5298C10.7426 8.57537 9.00127 8.18561 7.43101 7.39525C5.86074 6.60488 4.51032 5.43844 3.5 3.9998C3.5 3.9998 -0.5 12.9998 8.5 16.9998C6.44053 18.3978 3.98716 19.0987 1.5 18.9998C10.5 23.9998 21.5 18.9998 21.5 7.4998C21.4991 7.22126 21.4723 6.9434 21.42 6.6698C22.4406 5.6633 23.1608 4.39251 23.5 2.9998Z" />
    </svg>
  );
}
